import React from "react"
import { Link } from 'gatsby'
import SEO from "../../components/seo"

import Layout from "../../components/layout"
const LabsPage = () => {
    return (
        <Layout>
            <SEO title="Pricing" />
            <div className='p-12 text-center'>
                <h1 className='text-4xl text-center'>Laboratories and Trials</h1>
                <h1 className='text-xl text-center mt-4'>Are you interested in participating in one of our trials?
                    <br />
                    If so, please contact us today and we will get back to you.
                    <br />
                    <div className='grid justify-center p-8'>
                        <Link className='text-2xl inline-block px-6 py-4 font-medium leading-6
        text-center text-white uppercase transition bg-blue-700 rounded-full
        shadow ripple hover:shadow-lg hover:bg-blue-800 focus:outline-none'
                              to='/contact'>
                            Contact us today!
                        </Link>
                    </div>
                    </h1>
            </div>
        </Layout>
    )
}

export default LabsPage
